import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, IconButton, List, Toolbar, Typography } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSearchContext } from '../../SearchContext';
import SidebarItem from './sidebarItem';
import menu_icon from './../../images/navbar/menu_icon.svg';
import dashboard_icon from './../../images/navbar/dashboard_icon.svg';
import dashboard_selected_icon from './../../images/navbar/dashboard_selected_icon.svg';
import site_e_survey_icon from './../../images/navbar/site_e_survey_icon.svg';
import site_e_survey_selected_icon from './../../images/navbar/site_e_survey_selected_icon.svg';
import underwriting_icon from './../../images/navbar/underwriting_icon.svg';
import underwriting_selected_icon from './../../images/navbar/underwriting_selected_icon.svg';
import portfolio_icon from './../../images/navbar/portfolio_icon.svg';
import portfolio_selected_icon from './../../images/navbar/portfolio_selected_icon.svg';
import my_properties_icon from './../../images/navbar/my_properties_icon.svg';
import my_properties_selected_icon from './../../images/navbar/my_properties_selected_icon.svg';
import contact_icon from './../../images/navbar/contact_icon.svg';
import contact_selected_icon from './../../images/navbar/contact_selected_icon.svg';
import settings_icon from './../../images/navbar/settings_icon.svg';
import settings_selected_icon from './../../images/navbar/settings_selected_icon.svg';

const drawerWidth = '180px';

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      borderRight: '0.5px solid #E9EBEE',
      height: '100vh',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
        alignItems: 'center',
        textAlign: 'center',
      }),
    },
  })
);

const Sidebar = ({ open, toggleDrawer }) => {
  const { setSearchLat, setSearchLong, setSelectedBuilding, setHasLoadedData } = useSearchContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = (event) => {
    const value = event.currentTarget.getAttribute('value');
    setHasLoadedData(false);
    setSelectedBuilding(null);
    setSearchLat(null);
    setSearchLong(null);
    navigate(`/${value}`);
  };

  const mainSidebarItems = [
    { value: 'dashboard', icon: dashboard_icon, selectedIcon: dashboard_selected_icon, label: 'Dashboard', disabled: true },
    { section: t('adaptions_page.tools') },
    { value: 'prevent', icon: site_e_survey_icon, selectedIcon: site_e_survey_selected_icon, label: 'main_page.prevent' },
    { value: 'quantify', icon: underwriting_icon, selectedIcon: underwriting_selected_icon, label: 'main_page.quantify', disabled: true },
    { value: 'aggregate', icon: portfolio_icon, selectedIcon: portfolio_selected_icon, label: 'main_page.aggregate', disabled: true },
    { section: t('adaptions_page.archive') },
    { value: 'properties', icon: my_properties_icon, selectedIcon: my_properties_selected_icon, label: 'My Properties', disabled: true },
  ];
  

  // const bottomSidebarItems = [
  //   { value: 'contact', icon: contact_icon, selectedIcon: contact_selected_icon, label: 'Contact' },
  //   { value: 'settings', icon: settings_icon, selectedIcon: settings_selected_icon, label: 'Settings' },
  // ];

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'left',
          justifyContent: 'left',
          px: [1],
        }}
      >
      </Toolbar>
      <IconButton 
      onClick={toggleDrawer}
      disableRipple         
      sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginTop: 3,
          ml: open ? 2.5 : 0,
          '&:hover': {
            backgroundColor: 'transparent', // Prevent background color change on hover
          },
        }}>
        <img src={menu_icon} alt="menu-icon" />
      </IconButton>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <List component="nav" sx={{ flexGrow: 1 }}>
        {mainSidebarItems.map((item, index) => (
          item.section ? (
            <Typography
              key={index}
              color={open ? '#B5B9BF' : '#39393D'}
              sx={{ mt: 5, mb: 2, ml: open ? 2 : 0, fontSize: open ? '10px' : '8px' }}
            >
              {t(item.section)}
            </Typography>
          ) : (
            <SidebarItem
              key={item.value}
              value={item.value}
              icon={item.icon}
              selectedIcon={item.selectedIcon}
              label={t(item.label)}
              open={open}
              handleClick={handleClick}
              disabled={item.disabled}
            />
          )
        ))}
        </List>
        {/* <Box sx={{ mt: 'auto' }}>
          {bottomSidebarItems.map((item) => (
            <SidebarItem
              key={item.value}
              value={item.value}
              icon={item.icon}
              selectedIcon={item.selectedIcon}
              label={t(item.label)}
              open={open}
              handleClick={handleClick}
            />
          ))}
        </Box> */}
      </Box>
    </Drawer>
  );
};

export default Sidebar;

