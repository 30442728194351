import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, TextField, FormControlLabel, Button, FormControl, Switch, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSearchContext } from '../../SearchContext';
import axios from 'axios';
import { SearchResults } from './SearchResults';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LoaderButton } from '../../images/loaders/loader_white.svg';
import PropertyTickBoxes from './propertyTickBoxes';
import useTokenValidation from '../../useTokenValidation';

const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#E46371',
        opacity: 1,
        border: 0,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9EBEE',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const SearchBox = ({setIsAuthenticated}) => {
    const { returnPeriod, riskYear, setSelectedBuilding, searchLat, setSearchLat, searchLong, setSearchLong, isLoading, setIsLoading, setPropertyData, setHasLoadedData, setAddressNotFound, searchByAddress, setSearchByAddress, hasBasement, setHasBasement, isGroundFloor, setIsGroundFloor, experiencedSewageBackup, setExperiencedSewageBackup,propertyData  } = useSearchContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [results, setResults] = useState([]);
    const token = localStorage.getItem('token');
    const [address, setAddress] = useState('');
    const [validationErrors, setValidationErrors] = useState({
        hasBasement: false,
        isGroundFloor: false,
        experiencedSewageBackup: false,
    });

    useTokenValidation(setIsAuthenticated);

    const resetTickBoxes = () => {
      setHasBasement({
          yes: false,
          no: false,
          idk: false,
      });
      setIsGroundFloor({
          yes: false,
          no: false,
          idk: false,
      });
      setExperiencedSewageBackup({
          yes: false,
          no: false,
          idk: false,
      });
  };

    useEffect(() => {
      resetTickBoxes();
  }, []);

  const get_data = async (event) => {
    event.preventDefault(); // Prevent default form submission
    setAddressNotFound(false);
    setResults([]);
    setIsLoading(true);

    // Validate checkboxes
    const errors = {
        hasBasement: !(hasBasement.yes || hasBasement.no || hasBasement.idk),
        isGroundFloor: !(isGroundFloor.yes || isGroundFloor.no || isGroundFloor.idk),
        experiencedSewageBackup: !(experiencedSewageBackup.yes || experiencedSewageBackup.no || experiencedSewageBackup.idk),
    };

    setValidationErrors(errors);

    if (errors.hasBasement || errors.isGroundFloor || errors.experiencedSewageBackup) {
        setIsLoading(false);
        return;
    }

    try {
        let latitude = searchLat;
        let longitude = searchLong;

        // If searching by address, fetch coordinates
        if (searchByAddress && !latitude && !longitude) {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}building-coords/${address}/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const lat_long = response.data;
            latitude = lat_long.lat;
            longitude = lat_long.long;

            setSearchLat(latitude);
            setSearchLong(longitude);

            // Log the search with the address
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}user/log/searches/${address}`, {}, {
              headers: {
                  'Authorization': `Bearer ${token}`
              }
          });
        }else{
          // Log the search with the coordinates
          await axios.post(`${process.env.REACT_APP_API_BASE_URL}user/log/searches/${latitude},${longitude}`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
          });
        }

        // Fetch building information
        const buildingInfoResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}building-info/coordinates/filtered/${latitude},${longitude}/${returnPeriod}/${riskYear}/?has_basement=${!hasBasement.no}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const buildings_info = buildingInfoResponse.data;
        setResultsState(buildings_info);

    } catch (error) {
        errorHandler(error);
    } finally {
        setIsLoading(false);
    }
};


    const errorHandler = (error) => {
        console.log(error);
        if (error.response) {
            if (error.response.status === 429) {
                alert('You have exceeded your request limit. Please contact the administrator.');
            } else if (error.response.status === 404) {
                setAddressNotFound(true);
                navigate(`/prevent`);
            } else if (error.response.status === 504) {
                alert('Server timeout. Please try again later.');
            }
        } else {
            alert('Something went wrong. Please try again later.');
        }
        setIsLoading(false);
        setAddress("");
        resetTickBoxes();
        setSearchLat("");
        setSearchLong("");
    };

    const setResultsState = (buildings_info) => {
        setPropertyData(buildings_info);
        setSelectedBuilding(buildings_info['building_id']);
        setHasLoadedData(true);
        setSearchByAddress(true);
        navigate(`adaptions/${buildings_info['building_id']}?risk_year=${riskYear}&return_period=${returnPeriod}&has_basement=${Object.keys(hasBasement).find(key => hasBasement[key])}&is_ground_floor=${Object.keys(isGroundFloor).find(key => isGroundFloor[key])}&experienced_sewage_backup=${Object.keys(experiencedSewageBackup).find(key => experiencedSewageBackup[key])}`);
    };

    return (
        <Box sx={{
          display: 'inline-flex',
          padding: { xs: 3, sm: 2, lg: 3 },
          alignItems: 'flex-start',
          justifyContent: 'center',
          position: { xs: 'fixed', md: 'relative' },
          bottom: { xs: 0, md: 'auto' },
          left: { xs: '50%', md: 'auto' },
          transform: { xs: 'translateX(-50%)', md: 'none' },
          width: { xs: '100%', md: 'auto' },
          zIndex: 1000, // Ensure the search box is above other elements
        }}>
          <Paper component="form" onSubmit={get_data} sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: { xs: 2, lg: 3 },
            width: { xs: '90vw', md: '40vw', lg: '30vw' },
            maxWidth: '100%',
          }}>
            <Typography variant="h3" sx={{mb:1}}>{t('main_page.search_for_property')}</Typography>
            <Typography variant="h6" color="#8A8A8A">{t('main_page.enter_address_or_click_map')}</Typography>
            <FormControlLabel
              control={<CustomSwitch checked={searchByAddress} onChange={() => setSearchByAddress(!searchByAddress)}/>}
              label={searchByAddress ? t('main_page.search_by_address') : t('main_page.search_by_coordinates')}
            />
            <Box sx={{ paddingBottom: 2, paddingTop: 1 }}>
              <FormControl>
                {searchByAddress ? (
                  <TextField
                    fullWidth
                    id="address"
                    placeholder={t('main_page.enter_address')}
                    variant='outlined'
                    sx={{ justifyContent: 'flex-start', width: { xs: '80vw', sm: '85vw', md: '37vw', lg: '26.3vw' }, mt:-1}}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                ) : (
                  <>
                    <TextField
                      fullWidth
                      id="latitude"
                      placeholder={t('main_page.lat')}
                      variant='outlined'
                      sx={{ justifyContent: 'flex-start', mb: 2, width: { xs: '80vw', sm: '85vw', md: '37vw', lg: '26.3vw' } }}
                      value={searchLat}
                      onChange={(e) => setSearchLat(e.target.value)}
                    />
                    <TextField
                      fullWidth
                      id="longitude"
                      placeholder={t('main_page.lng')}
                      variant='outlined'
                      sx={{ justifyContent: 'flex-start', width: { xs: '80vw', sm: '85vw', md: '37vw', lg: '26.3vw' } }}
                      value={searchLong}
                      onChange={(e) => setSearchLong(e.target.value)}
                    />
                  </>
                )}
                {address !== "" && searchByAddress &&
                  <SearchResults results={results} setResults={setResults} address={address} setAddress={setAddress} searchByAddress={searchByAddress} />
                }
              </FormControl>
            </Box>
            <PropertyTickBoxes validationErrors={validationErrors} />
            <Button type="submit" fullWidth variant="contained" color="primary" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto' }}> {!isLoading ? t('main_page.search') : <LoaderButton className='spinner' />}</Button>
          </Paper>
        </Box>      
    );
}

export default SearchBox;
