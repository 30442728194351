import React from 'react';
import { ListItem, ListItemButton, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SidebarItem = ({ value, icon, selectedIcon, label, open, handleClick, disabled }) => {
  const location = useLocation();
  const { t } = useTranslation();

  // Check if the current path starts with the base path
  const isSelected = location.pathname.startsWith(`/${value}`);

  return (
    <ListItem>
      <ListItemButton
        value={value}
        onClick={!disabled ? handleClick : null}
        sx={{ 
          padding: '7px 7px 7px 5px',
          justifyContent: open ? '' : 'center',
          alignItems: open ? '' : 'center',
          backgroundColor: isSelected ? '#FDF4F5' : 'transparent',
          cursor: disabled ? 'default' : 'pointer',
          opacity: disabled ? 0.5 : 1,
          pointerEvents: disabled ? 'none' : 'auto',
          borderRadius: isSelected ? '3px' : '0px', 
        }}
        disabled={disabled}
      >
        <img src={isSelected ? selectedIcon : icon} alt={`${value}-icon`} height={30} />
        {open && <Typography color="black" ml>{t(`${label}`)}</Typography>}
      </ListItemButton>
    </ListItem>
  );
};

export default SidebarItem;
