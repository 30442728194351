import React, { useState } from "react";
import Login from "./components/login/login";
import ChangePassword from "./components/login/ChangePassword";
import ForgotPassword from "./components/login/forgotPassword";
import ResetPassword from "./components/login/resetPassword";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import GlobalNav from "./components/navbar/globalNav";
import { CssBaseline, Box } from "@mui/material";
import Adaptions from "./components/adaptions/adaptions";
import MapDash from "./components/map/mapDash";
import OnlineReport from "./components/adaptions/report/onlineReport";
import UnderConstruction from "./components/underConstruction";
import UnderMaintenance from "./components/underMaintenance";

const PrivateRoute = ({ element, isAuthenticated }) => {
  return isAuthenticated ? element : <Navigate to="/login" />;
};

function App() {
  const storedToken = localStorage.getItem("token");
  const [isAuthenticated, setIsAuthenticated] = useState(!!storedToken);
  const [underMaintenance, setUnderMaintenance] = useState(false);

  return (
    <BrowserRouter>
    {underMaintenance ? (
      <UnderMaintenance/>
    ):
    (<>
      <Box sx={{ display: isAuthenticated ? 'flex' : 'none' }}>
      <CssBaseline />
      <GlobalNav isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}/>
      <Box
        component="main"
        sx={{
          backgroundColor: '#FFFFFF',
          flexGrow: 1,
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute
              element={<UnderConstruction pageName={'Home dashboard'}/>}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute
              element={<UnderConstruction pageName={'Home dashboard'}/>}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/prevent"
          element={
            <PrivateRoute
              element={<MapDash setIsAuthenticated={setIsAuthenticated}/>}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/view-report"
          element={
            <PrivateRoute
              element={<OnlineReport setIsAuthenticated={setIsAuthenticated}/>}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/quantify"
          element={
            <PrivateRoute
              element={<UnderConstruction pageName={'Quantify'}/>}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/aggregate"
          element={
            <PrivateRoute
              element={<UnderConstruction pageName={'Aggregate'}/>}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/properties"
          element={
            <PrivateRoute
              element={<UnderConstruction pageName={'Properties archieve'}/>}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/contact"
          element={
            <PrivateRoute
              element={<UnderConstruction pageName={'Contacts'}/>}
              isAuthenticated={isAuthenticated}
            />
          }
        /><Route
        path="/settings"
        element={
          <PrivateRoute
            element={<UnderConstruction pageName={'Settings'}/>}
            isAuthenticated={isAuthenticated}
          />
        }
      />
        <Route
          path="prevent/adaptions/:building_id"
          element={
            <PrivateRoute
              element={<Adaptions setIsAuthenticated={setIsAuthenticated}/>}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        </Routes>
      </Box>
    </Box> 
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/change-password" element={<ChangePassword isAuthenticated={isAuthenticated}  setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/forgot-password" element={<ForgotPassword isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/reset-password/:uidb64/:token" element={<ResetPassword isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
      </Routes>
      </>)}
    </BrowserRouter>
  );
}

export default App;