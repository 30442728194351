import React from "react";
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Link, Box, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const BreadCrumbs = ({ pageNames, viewReport, handleAdaptionClick }) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <Breadcrumbs sx={{ ml: 3 }} separator="›">
                <Link underline="hover" fontSize="12px" color="inherit" href="/prevent">
                    <HomeIcon sx={{ verticalAlign: 'middle' }} fontSize="inherit" color="primary" />
                </Link>

                {pageNames.map((pageName, index) => (
                    <Link underline="hover" color="inherit" href={pageName.route} key={index}>
                        <Typography variant="breadcrumbs">{t(pageName.name)}</Typography>
                    </Link>
                ))}

            </Breadcrumbs>
            <Box sx={{ mr: 3 }}>
                <Typography variant="breadcrumbs">{t('adaptions_page.where_info_coming_from')}</Typography>
                <Link fontSize="10px" color="primary" href="/prevent">{t('adaptions_page.learn_more')}</Link>
            </Box>
        </Box>
    );
};

export default BreadCrumbs;
