import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginWelcome from "./loginWelcome";
import LoginForm from "./loginForm";
import Box from '@mui/material/Box';

const Login = ({ isAuthenticated, setIsAuthenticated }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/prevent");
        }
    }, [isAuthenticated, navigate]);

    return (
        <Box
            className="login-container"
            display="flex"
            height="100vh"
            width='100vw'
        >
            <Box
                sx={{ 
                    display: { xs: 'none', md: 'flex' },
                    width: '58%',
                    height: '100%',
                    backgroundColor: '#F8F6F4',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <LoginWelcome />
            </Box>
            <Box
                sx={{ 
                    display: 'flex',
                    width: { xs: '100%', md: '42%'},
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <LoginForm setIsAuthenticated={setIsAuthenticated} />
            </Box>
        </Box>
    );
}

export default Login;
