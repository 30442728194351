import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import GoogleMap from '../map/google_map';
import AdaptionsTable from './adaptionsTable';
import OnlineReport from './report/onlineReport';
import { useSearchContext } from '../../SearchContext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import RiskLevels from './riskLevels';
import PropertyAddressPaper from './propertyAddressPaper';
import { ReactComponent as LoaderButton } from '../../images/logos/logo.svg';
import '../../css/loaders.css'
import useTokenValidation from '../../useTokenValidation';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

export default function Adaptions({ setIsAuthenticated }) {
    const { hasBasement, isGroundFloor, experiencedSewageBackup,setIsGroundFloor, setExperiencedSewageBackup, setPropertyData, setRiskYear, setHasBasement, setReturnPeriod, setSelectedBuilding, isLoading, propertyData, selectedBuilding, setRisksToFilter, returnPeriod, riskYear, blueSpotData, streamsData } = useSearchContext();
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [viewReport, setViewReport] = useState(false);
    const [staticMap, setStaticMap] = useState("");
    const [staticMapLoading, setStaticMapLoading] = useState(false);
    const token = localStorage.getItem('token');

    // parameters from the URL. These are used 
    // when the user navigates to the page directly from the URL.
    // Otherwise, the parameters are set in the search context.
    const { building_id } = useParams();
    const [searchParams] = useSearchParams();
    const risk_year = searchParams.get('risk_year');
    const return_period = searchParams.get('return_period');
    const has_basement = searchParams.get('has_basement');
    const is_ground_floor = searchParams.get('is_ground_floor');
    const experienced_sewage_backup = searchParams.get('experienced_sewage_backup');



    useTokenValidation(setIsAuthenticated);

    useEffect(() => {
        if ((!propertyData || !selectedBuilding) || (selectedBuilding !== building_id)) {
            hasBasement[has_basement] = true;
            isGroundFloor[is_ground_floor] = true;
            experiencedSewageBackup[experienced_sewage_backup] = true;
            setRiskYear(risk_year);
            setReturnPeriod(return_period);
            setHasBasement({ ...hasBasement });
            setIsGroundFloor({ ...isGroundFloor });
            setExperiencedSewageBackup({ ...experiencedSewageBackup });
            setSelectedBuilding(building_id);
            getPropertyData(); 
;
        }
     
    }
        , [building_id]);

    useEffect(() => {
        if (propertyData.solutions) {
        setRisksToFilter([]);
        setStaticMap("");
        getStaticMap();
        }
     
    }
        , [propertyData, selectedBuilding, returnPeriod, riskYear]);

    const getPropertyData =  async () => {
        try {
            await axios.get(`${process.env.REACT_APP_API_BASE_URL}building-info/building-id/filtered/${building_id}/${return_period}/${risk_year}/?has_basement=${!hasBasement.no}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                setPropertyData(response.data);
            })}
        
        catch (error) {
            console.log(error);
        }
        }

    const getStaticMap = async () => {
        setStaticMapLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}static-map/building-id/${propertyData.building_id}/?return_period=${returnPeriod}&year=${riskYear}`,
                {
                    "building_info": propertyData,
                    "blue_spot": blueSpotData,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            const staticMapURL = response.data.url;
            setStaticMap(staticMapURL);
            setStaticMapLoading(false);
        }
        catch (error) {
            setStaticMapLoading(false);
            console.log(error);
        }
    }

    const handleAdaptionClick = () => {
        setViewReport(false);
    };


    return (
        <Box
            component="main"
            sx={{
                backgroundColor: '#FFFFFF',
                flexGrow: 1,
                height: '100%',
                overflow: 'auto',
                mt: 8
            }}
        >
            {!isSmallerScreen &&
                <Box sx={{ mt: 1, mb: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                    <Breadcrumbs sx={{ ml: 3 }} separator="›">
                        <Link underline="hover" fontSize="12px" color="inherit" href="/prevent">
                            <HomeIcon sx={{ verticalAlign: 'middle' }} fontSize="inherit" color="primary" />
                        </Link>
                        <Link underline="hover" color="inherit" href="/prevent">
                            <Typography variant="breadcrumbs">{t('main_page.prevent')}</Typography>
                        </Link>
                        {viewReport ? (
                            <Link underline="hover" color="inherit" onClick={handleAdaptionClick} style={{ cursor: 'pointer' }}>
                                <Typography variant="breadcrumbs">{t('adaptions_page.adaptions')}</Typography>
                            </Link>
                        ) : (
                            <Typography variant="breadcrumbs">{t('adaptions_page.adaptions')}</Typography>
                        )}
                        {viewReport && (
                            <Typography variant="breadcrumbs">{t('adaptions_page.online_report')}</Typography>
                        )}
                    </Breadcrumbs>
                    <Box sx={{ mr: 3 }}>
                        <Typography variant="breadcrumbs">{t('adaptions_page.where_info_coming_from')}</Typography>
                        <Link fontSize="10px" color="primary" href="/prevent">{t('adaptions_page.learn_more')}</Link>
                    </Box>
                </Box>
            }
            {isLoading &&
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '80vh',
                        width: '100%',
                    }}>
                    <LoaderButton className="logo-loader" />
                </Box>
            }
            <Box sx={{ display: isLoading ? 'none' : 'block' }}>
                <Container maxWidth="100%" >
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4} lg={3.5} sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}>

                            <PropertyAddressPaper />

                            {propertyData.risk_levels && (
                                <RiskLevels riskLevels={propertyData.risk_levels} />
                            )}
                            {propertyData.geom_plot && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexGrow: 1,
                                    position: 'relative',
                                    height: '42vh',
                                    minHeight: '40vh',
                                    maxHeight: '45vh',
                                    overflow: 'hidden',
                                    mt: 2,
                                }}
                            >
                                <GoogleMap />
                            </Box>
                            )}
                        </Grid>

                        <Grid item xs={12} md={8} lg={8.5}>
                            <Paper
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '86vh',
                                    overflow: 'scroll',
                                    border: '0.5px solid #E9EBEE',
                                }}
                            >
                                {viewReport && (
                                    <OnlineReport riskSolutions={propertyData.solutions} riskLevels={propertyData.risk_levels} staticMap={staticMap} staticMapLoading={staticMapLoading} precipitation={propertyData.precipitation_mm_24h} runoffVolume={(propertyData.runoff_volume_m3 * 1000)} viewReport={viewReport} />
                                )}
                                {!viewReport && propertyData.solutions && propertyData.risk_levels && (
                                    <AdaptionsTable riskSolutions={propertyData.solutions} riskLevels={propertyData.risk_levels} viewReport={viewReport} setViewReport={setViewReport} />
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    
    );
}
