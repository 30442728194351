import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSearchContext } from '../../SearchContext';
import { BottomNavigation, BottomNavigationAction, AppBar, Box, List } from '@mui/material';
import styled from '@mui/material/styles/styled';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import SidebarItem from './sidebarItem';
import menu_icon from './../../images/navbar/menu_icon.svg';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import LanguageSelect from '../login/languageSelect';
import dashboard_icon from './../../images/navbar/dashboard_icon.svg';
import dashboard_selected_icon from './../../images/navbar/dashboard_selected_icon.svg';
import site_e_survey_icon from './../../images/navbar/site_e_survey_icon.svg';
import site_e_survey_selected_icon from './../../images/navbar/site_e_survey_selected_icon.svg';
import underwriting_icon from './../../images/navbar/underwriting_icon.svg';
import underwriting_selected_icon from './../../images/navbar/underwriting_selected_icon.svg';
import portfolio_icon from './../../images/navbar/portfolio_icon.svg';
import portfolio_selected_icon from './../../images/navbar/portfolio_selected_icon.svg';
import my_properties_icon from './../../images/navbar/my_properties_icon.svg';
import my_properties_selected_icon from './../../images/navbar/my_properties_selected_icon.svg';

export const BottomAppNav = () => {
    const { setSearchLat, setSearchLong, setSelectedBuilding, setHasLoadedData } = useSearchContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [value, setValue] = useState(0);

    const handleClick = (event, newValue) => {
        setValue(newValue); // Update selected value
        const selectedItem = mobileNavItems[newValue];
        setHasLoadedData(false);
        setSelectedBuilding(null);
        setSearchLat(null);
        setSearchLong(null);
        navigate(`/${selectedItem.value}`); // Navigate to the selected route
    };

    const mobileNavItems = [
        { value: 'dashboard', icon: dashboard_icon, selectedIcon: dashboard_selected_icon, label: 'Dashboard' },
        { value: 'prevent', icon: site_e_survey_icon, selectedIcon: site_e_survey_selected_icon, label: 'Site E-Survey' },
        { value: 'underwriting', icon: underwriting_icon, selectedIcon: underwriting_selected_icon, label: 'Underwriting' },
        { value: 'portfolio', icon: portfolio_icon, selectedIcon: portfolio_selected_icon, label: 'Portfolio' },
        { value: 'properties', icon: my_properties_icon, selectedIcon: my_properties_selected_icon, label: 'My Properties' },
    ];

    return (
        <BottomNavigation
            value={value}
            onChange={handleClick}
            showLabels
            sx={{ width: '100%', position: 'fixed', bottom: 0, zIndex: 1000 }}
        >
            {mobileNavItems.map((item, index) => (
            <BottomNavigationAction
                key={index}
                label={t(item.label)}
                icon={<img src={item.icon} alt={item.label} style={{ width: 22, height: 22 }} />}
                value={index}
                sx={{
                    minWidth: 0,
                    padding: 0,
                    '& .MuiBottomNavigationAction-label': {
                        fontSize: '0.75rem',
                        lineHeight: '1.2',
                        padding: 0,
                        margin: 0,
                    },
                }}
            />
            ))}
        </BottomNavigation>
    );
};


export const MobileTopbar = ({ open, toggleDrawer}) => {
    const location = useLocation();
    const { t } = useTranslation();

    const getLocationLabel = (path) => {
        switch (path) {
            case '/dashboard':
                return t('Dashboard');
            case '/prevent':
                return t('Site E-Survey');
            case '/underwriting':
                return t('Underwriting');
            case '/portfolio':
                return t('Portfolio');
            case '/properties':
                return t('My Properties');
            default:
                return t('Mitigrate');
        }
    };

    return(
        <AppBar position="absolute">
            <Toolbar
                sx={{
                    backgroundColor: 'white',
                    color: 'black',
                    px: 0.5
                }}
            >
                <IconButton 
                    onClick={toggleDrawer}
                    disableRipple         
                    sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    ml: open ? 2.5 : 0,
                    mt: 1,
                    '&:hover': {
                        backgroundColor: 'transparent', // Prevent background color change on hover
                    },
                    }}>
                    <img src={menu_icon} alt="menu-icon" />
                </IconButton>
                <Typography
                    variant="h5"
                    noWrap
                    sx={{ flexGrow: 1, textAlign: 'center' }}
                >
                    {getLocationLabel(location.pathname)}
                </Typography>
                <IconButton>
                    <NotificationsOutlinedIcon />
                </IconButton>
                <LanguageSelect fullWidth={false} outlined={false} />
            </Toolbar>
        </AppBar>
    )
}

const drawerWidth = {xs: '25vw', sm: '50vw'};

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        position: 'fixed',
        whiteSpace: 'nowrap',
        width: open ? drawerWidth : 0,
        transition: theme.transitions.create(['width', 'transform'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        transform: open ? 'translateX(0)' : `translateX(-100%)`,
        overflow: 'hidden', // Ensure contents are hidden when closed
        boxSizing: 'border-box',
        zIndex: theme.zIndex.drawer + 1,
      },
    })
  );

export const MobileSidebar = ({ open, toggleDrawer }) => {
    const { setSearchLat, setSearchLong, setSelectedBuilding, setHasLoadedData } = useSearchContext();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClick = (event) => {
        const value = event.currentTarget.getAttribute('value');
        setHasLoadedData(false);
        setSelectedBuilding(null);
        setSearchLat(null);
        setSearchLong(null);
        navigate(`/${value}`);
    };

    const mainSidebarItems = [
        { value: 'dashboard', icon: dashboard_icon, selectedIcon: dashboard_selected_icon, label: 'Dashboard', disabled: true },
        { section: t('adaptions_page.tools') },
        { value: 'prevent', icon: site_e_survey_icon, selectedIcon: site_e_survey_selected_icon, label: 'Prevent' },
        { value: 'quantify', icon: underwriting_icon, selectedIcon: underwriting_selected_icon, label: 'Quantify', disabled: true },
        { value: 'aggregate', icon: portfolio_icon, selectedIcon: portfolio_selected_icon, label: 'Aggregate', disabled: true },
        { section: t('adaptions_page.archive') },
        { value: 'properties', icon: my_properties_icon, selectedIcon: my_properties_selected_icon, label: 'My Properties'},
    ];

    return (
        <Drawer variant="permanent" open={open}>
        <IconButton 
            onClick={toggleDrawer}
            disableRipple         
            sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            ml: 2.5,
            mt: 1,
            '&:hover': {
                backgroundColor: 'transparent', // Prevent background color change on hover
            },
            }}>
            <img src={menu_icon} alt="menu-icon" />
        </IconButton>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <List component="nav" sx={{ flexGrow: 1 }}>
            {mainSidebarItems.map((item, index) => (
                item.section ? (
                <Typography
                    key={index}
                    color={'#B5B9BF'}
                    sx={{ mt: 3, ml: 1, fontSize: '10px' }}
                >
                    {t(item.section)}
                </Typography>
                ) : (
                <SidebarItem
                    key={item.value}
                    value={item.value}
                    icon={item.icon}
                    selectedIcon={item.selectedIcon}
                    label={t(item.label)}
                    open={open}
                    handleClick={handleClick}
                />
                )
            ))}
            </List>
        </Box>
        </Drawer>
    );
    };
